import { inject, Injectable, signal } from '@angular/core';
import { BehaviorSubject, filter, Observable, ReplaySubject, retry, switchMap, tap } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

import { User } from '../../interfaces/user.interface';
import { AuthToken } from '../../interfaces/auth-token.interface';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { functionalPermissionsMap, IFunctionalPermissions } from './../../../core/constants/functional-permissions-map';
import { userPermissionsMap } from '../../constants/user-Permissions.map';
import { DatadogService } from '../datadog.service';
import { IStaff } from '../../interfaces/staff.interface';
import { SettingsService } from '@pages/charging-settings/services/settings.service';
import { UserPreferencesService } from '../user-preferences.service';
import { SharedService } from '@shared/services/shared.service';
export interface IUserPermission {
  bitPosition: number;
  description: string;
  externalUserPermissionReference: string;
  name: string;
}

export interface IUserAuthorizationLevel {
  roles?: Array<any>;
  userPermissions?: string[];
  functionalPermissions?: string[];
  email?: string;
  external_staff_reference: string;
  external_customer_reference: string;
  initialized: boolean;
}

export interface IUserPreferences {
  equipmentIdentification: string;
  equipmentUnit: string;
  gasUnit: string;
}

export enum EquipmentUnitPreference {
  METRIC = 'Metric',
  IMPERIAL = 'Imperial',
}
@Injectable({
  providedIn: 'root',
})
export class UserService {
  sharedService = inject(SharedService);
  private settingsService = inject(SettingsService);
  private userPreferencesService = inject(UserPreferencesService);
  equipmentIdentification = new ReplaySubject<string>(1);
  equipmentIdentification$ = this.equipmentIdentification.asObservable();

  vehicleIdentifier = signal<string>('');

  token = new BehaviorSubject<string>('');

  // THESE 2 NOT BEING USED???
  private _userData = new BehaviorSubject<User | null>(null);
  currentUser$ = this._userData.asObservable();

  private _userDataStaff = new ReplaySubject<IStaff>(1);
  currentUserStaff$ = this._userDataStaff.asObservable();
  jwtHelper: JwtHelperService;

  private _userAuthorizationLevel = new ReplaySubject<IUserAuthorizationLevel>();
  userAuthorizationLevel$ = this._userAuthorizationLevel
    .asObservable()
    .pipe(filter((res: IUserAuthorizationLevel) => res.initialized == true));
  // store permissions in this service?

  isCustomerOnboarded = new ReplaySubject<boolean>(1);
  isCustomerOnboarded$ = this.isCustomerOnboarded.asObservable();

  constructor(
    private httpClient: HttpClient,
    private dataDogService: DatadogService,
  ) {
    this.jwtHelper = new JwtHelperService();
  }

  getCurrentUser() {
    //TODO REMOVE/CLEAN UP
    // JUST DONT DO THIS.
    // DONT CALL get value from behaviourSubject
    // read more for referance:
    // https://stackoverflow.com/questions/37089977/how-to-get-current-value-of-rxjs-subject-or-observable/45227115#45227115

    return this._userData.getValue();
  }

  getOnboardedStatus() {
    const CustomerOnboardedUrl = `${environment.publicChargingService}onboarding/CustomerOnboarded`;
    return this.httpClient.get<boolean>(CustomerOnboardedUrl).pipe(retry(2));
  }

  decodeTokenAndSetUserData(token: string): Observable<any> {
    //TODO userpermissions
    const decoded = this.jwtHelper.decodeToken(token);
    this._userData.next(this.mapUserDataFromToken(decoded));

    return this.getOnboardedStatus().pipe(
      tap((customerOnboardedResponse: boolean) => this.isCustomerOnboarded.next(customerOnboardedResponse)),
      switchMap(() => {
        return this.settingsService.getStaffByReference(decoded.customer?.external_staff_reference).pipe(
          tap((staff: IStaff) => {
            let userAuthLevel = this.createUserAuthLevel(staff, decoded);

            this._userAuthorizationLevel.next(userAuthLevel);
            this.dataDogService.logUserInfo(decoded, userAuthLevel, staff);
            this.settingsService.externalStaffReference = staff.externalStaffReference;

            this.token.next(token);
            this.userPreferencesService.usersPreferredVehicleIdentifier.set(staff.equipmentIdentification);

            this._userDataStaff.next(staff);
            this.equipmentIdentification.next(staff.equipmentIdentification);
            this.vehicleIdentifier.set(staff.equipmentIdentification);
            this.sharedService.languageCode.set(staff.languageCode);
          }),
        );
      }),
    );
  }

  createUserAuthLevel(staff: IStaff, decodedToken: any): IUserAuthorizationLevel {
    let createUserAUthLevel: IUserAuthorizationLevel = {
      roles: staff.roles,
      userPermissions: this.mapUserPermissions(decodedToken),
      functionalPermissions: this.mapFunctionalPermissions(decodedToken),
      email: decodedToken.email,
      external_staff_reference: decodedToken.customer?.external_staff_reference,
      external_customer_reference: decodedToken.customer?.external_customer_reference,
      initialized: true,
    };

    return createUserAUthLevel;
  }

  mapUserPermissions(decodedToken: any) {
    return decodedToken.customer?.user_permissions.map(
      (perm: any) => userPermissionsMap.find((funcP: IUserPermission) => funcP.bitPosition === perm)?.name,
    );
  }

  mapFunctionalPermissions(decodedToken: any) {
    return decodedToken.customer?.functional_permissions.map(
      (perm: any) => functionalPermissionsMap.find((funcP: IFunctionalPermissions) => funcP.bit === perm)?.name,
    );
  }

  mapUserDataFromToken(decoded: AuthToken): User {
    return {
      givenName: decoded.given_name,
      familyName: decoded.family_name,
      email: decoded.email,
      externalStaffReference: decoded.customer?.external_staff_reference,
      externalCustomerReference: decoded.customer?.external_customer_reference,
      userPermissions: decoded.customer?.user_permissions,
      functionalPermissions: decoded.customer?.functional_permissions,
      roles: decoded.customer?.roles,
    };
  }

  clearUser() {
    this._userData.next(null);
  }
}
