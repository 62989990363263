export interface IFunctionalPermissions {
  externalFunctionalPermissionReference: string;
  bit: number;
  shortId: number;
  name: string;
  description: string;
}

export const functionalPermissionsMap: Array<IFunctionalPermissions> = [
  {
    externalFunctionalPermissionReference: '8d004b31-bd5a-40af-8754-d05def4a4c6d',
    bit: 45,
    shortId: 45,
    name: 'ViewChargingDashboard',
    description: 'Give access to the Charging dashboard',
  },
  {
    externalFunctionalPermissionReference: '985544ba-84c7-4ad2-a494-717cd9733cc1',
    bit: 46,
    shortId: 46,
    name: 'ScaniaPublicCharging',
    description: 'Give access to Scanias public charging services',
  },
];
